import {DateService, Dictionary, InjectableStore2Feature} from '@softline/core';
import {computed, inject, Injectable} from '@angular/core';
import {LastUsed} from './last-used';

@Injectable({
  providedIn: 'root'
})
export class LastUsedStore2<T> extends InjectableStore2Feature<Readonly<Dictionary<T[]>>> {

  dateService = inject(DateService);

  lastUsed = (key: string) => computed(() => {
    const value = this.state()[key] as LastUsed<T>[];
    return value ?? [];
  })

  constructor() {
    super();
  }

  add(key: string, id: string | number, object: T): void {
    const timestamp = this.dateService.now();
    let lastUsed = this.state()[key] as LastUsed<T>[] ?? [];
    lastUsed = [{ id, object, timestamp }, ...lastUsed];
    if(lastUsed.length > 10)
      lastUsed.pop();
    this.commitPatch({ [key]: lastUsed } as any);
  }

  clear(): void {
    this.commit({ });
  }

  override getDefaultState(): Dictionary<T[]> {
    return {} as Dictionary<T[]>;
  }
}
