import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Supplier} from "../../../../../../lieferantenstamm/src/lib/types/supplier";
import {IsDefinedNotEmptyPipe} from "@softline/ui-core";

@Component({
  selector: 'soft-lieferanten-view',
  standalone: true,
  imports: [CommonModule, IsDefinedNotEmptyPipe],
  templateUrl: './lieferanten-view.component.html',
  styleUrl: './lieferanten-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LieferantenViewComponent {
  value = input<Supplier | null>(null);
}
