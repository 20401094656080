import { EntityInputStrategy, ModalStore, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import { CancelledError, isDefined, SOFTLINE_DATA_VALUE, Store } from "@softline/core";
import * as FieldOkComponentStore from "../../../field-ok-component.store";
import { SOFTLINE_FEATURE_FIELD_OK } from "../../../dynamic.shared";
import { FieldOkConfig, FieldOkParameters } from "../../../data/field-ok";
import { FieldOkDialog } from "../dialog/field-ok.dialog";
import { inject, Injector, signal } from '@angular/core';
import { handleRequestErrors, LastUsedStore2 } from '@softline/application';

export interface FieldOkStrategyOptions {
  config: FieldOkConfig;

  componentId: string;
  title: string;
  subtitle?: string | null;

  type: 'single' | 'multi' | 'query'
}

export interface FieldOkInputParams<TEntity, TParams> {
  current?: TEntity;
  parameters?: TParams;
}

export abstract class FieldOkStrategy<TValue, TItem, TParams extends FieldOkInputParams<TValue, object> = FieldOkInputParams<TValue, object>> extends EntityInputStrategy<TValue, TParams> {

  protected modalId: string | null = null;
  protected queryParams: FieldOkParameters | null = null;

  // protected lastUsedStore = inject(LastUsedStore2);

  activatedView = signal(0);
  loading = signal(false);
  loaded = signal(false);

  items$ = this.store.observe(
    SOFTLINE_FEATURE_FIELD_OK,
    FieldOkComponentStore.getters.list,
    this.options.componentId
  );

  filter$ = this.store.observe(
    SOFTLINE_FEATURE_FIELD_OK,
    FieldOkComponentStore.getters.filter,
    this.options.componentId
  );

  sort$ = this.store.observe(
    SOFTLINE_FEATURE_FIELD_OK,
    FieldOkComponentStore.getters.sort,
    this.options.componentId
  );


  constructor(
    protected store: Store,
    protected injector: Injector,
    protected uuid: () => string,
    public options: FieldOkStrategyOptions) {
    super();
  }

  async activateView(name: string | string[] | null | undefined | 0) {
    if(!isDefined(name))
      return;

    const views = this.options.config.views
    let index = 0;
    if(Array.isArray(name)){
      for(let item of name){
        index = views.findIndex(o => o.name === item);
        if(index > -1)
          break;
      }
    }
    else if(typeof name === 'string')
      index = views.findIndex(o => o.name === name);
    this.activatedView.set(index > -1 ? index : 0);
  }

  override async open(input: string | null, options: TParams): Promise<TValue | null | 'DISMISSED' | 'CANCELED'> {
    let view = this.options.config.defaultView;
    if((input?.length ?? 0) > 0 || this.options.config.autoQuery) {
      const result = await this.executeQuery(input, options.parameters)
      if(result === 'CANCELED')
        return 'CANCELED';
      view = this.options.config.defaultDataView ?? this.options.config.defaultView;
    }

    return await this.openDialog(view, options);
  }

  async query(parameters: TParams['parameters']): Promise<TItem[] | 'CANCELED'> {
    this.store.commit(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.mutations.addOrPatch,
      {
        key: this.options.componentId,
        component: {list: []}
      }
    );
    await this.activateView(this.options.config.defaultDataView);
    return this.executeQuery(null, parameters);
  }

  protected async openDialog(view: string | string[] | null | undefined | 0, parameters?: TParams): Promise<any> {
    await this.activateView(view)

    this.modalId = this.uuid();
    const injector = Injector.create({
      providers: [
        {provide: FieldOkStrategy, useValue: this},
        {provide: SOFTLINE_DATA_VALUE, useValue: parameters},
      ],
      parent: this.injector
    })
    const result = await this.store.dispatch(SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<any, any>(),
      {
        id: this.modalId,
        component: FieldOkDialog,
        data: {
          ...this.options
        },
        dismiss: true,
        getInjector: () => injector
      })
    this.modalId = null;
    return result;
  }

  protected async executeQuery(input: string | null, params?: TParams['parameters']): Promise<TItem[] | 'CANCELED'> {
    this.loading.set(true);
    this.loaded.set(false);
    this.queryParams = this.getQueryParams(input, params ?? {});
    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_FIELD_OK,
        FieldOkComponentStore.actions.query,
        {
          componentId: this.options.componentId,
          params: this.queryParams,
          name: this.options.config.name
        }
      );
      this.loading.set(false);
      this.loaded.set(true);

      return result as TItem[];
    } catch (e) {
      if(!(e instanceof CancelledError))
        handleRequestErrors(this.store, e);
      console.error(e);
      this.loading.set(false);
      this.loaded.set(false);
      if(e instanceof CancelledError)
        return 'CANCELED';
    }
    return [];
  }

  protected async executeValidation(input: string | null, params?: TParams['parameters'], multiValued?: boolean): Promise<TValue | TItem[] | string | null | 'CANCELED'> {
    this.loading.set(true);
    this.loaded.set(false);
    try {
      this.queryParams = this.getQueryParams(input, params, multiValued);
      let result = await this.store.dispatch(
        SOFTLINE_FEATURE_FIELD_OK,
        FieldOkComponentStore.actions.validate,
        {
          componentId: this.options.componentId,
          params: this.queryParams,
          name: this.options.config.name,
        }
      );

      this.loading.set(false);
      this.loaded.set(true);

      return result as TValue | TItem[] | string //TODO: Remove as object
    } catch (e) {
      if(!(e instanceof CancelledError))
        handleRequestErrors(this.store, e);
      console.log(e);
      this.loading.set(false);
      this.loaded.set(false);
      if(e instanceof CancelledError)
        return 'CANCELED';
    }
    return null;
  }

  async resolve(value: any): Promise<void> {
    if(!this.modalId)
      return;

    try {
      const result = await this.loadResult(value.id);
      await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.resolve(),
        {id: this.modalId, result }
      )
    }
    catch (e) {
      handleRequestErrors(this.store, e)
    }
  }

  async loadResult(id: string | number): Promise<object | null> {
    if(!this.queryParams)
      throw new Error('[FieldOkEntityInputStrategy] loadResult: no queryparams set (probably not queried or validated before)')
    let result = await this.store.dispatch(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.actions.loadResult,
      {
        componentId: this.options.componentId,
        params: this.queryParams,
        name: this.options.config.name,
        id,
      }
    ) as object;
    // this.lastUsedStore.add(this.options.config.name, id, result);
    return result ?? null;
  }

  protected getQueryParams(input: string | null, parameters?: object, multiValued?: boolean): FieldOkParameters {
    return {
      filter: input,
      multiValued: multiValued ?? false,
      parameters: parameters ?? {}
    };
  }

  override async cancel(): Promise<boolean> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.actions.cancel,
      this.options.componentId
    );
    return true;
  }
}
