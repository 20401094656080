import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Product} from '../../types/product';
import {IsDefinedNotEmptyPipe} from '@softline/ui-core';

@Component({
  selector: 'soft-artikel-view',
  standalone: true,
  imports: [CommonModule, IsDefinedNotEmptyPipe],
  templateUrl: './artikel-view.component.html',
  styleUrl: './artikel-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtikelViewComponent {
  value = input<Product | null>(null);
}
