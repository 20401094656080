<div class="flex gap-4 items-center">
  <div class="bg-surface-tertiary rounded w-20 h-20">
    &nbsp;
  </div>
  <div class="flex flex-col">
    <h6 class="soft-overline !mb-0 p-0">{{ value()?.key }}</h6>
    <h6 class="text-base font-semibold">{{ value()?.language?.name }}</h6>
    @if (value()?.language?.description1 | isDefinedNotEmpty) {
      <h6 class="text-sm text-light">{{ value()?.language?.description1 }}</h6>
    }
    @if (value()?.language?.description2 | isDefinedNotEmpty) {
      <h6 class="text-sm text-light">{{ value()?.language?.description2 }}</h6>
    }
    @if (value()?.language?.description3 | isDefinedNotEmpty) {
      <h6 class="text-sm text-light">{{ value()?.language?.description3 }}</h6>
    }
    @if (value()?.language?.description4 | isDefinedNotEmpty) {
      <h6 class="text-sm text-light">{{ value()?.language?.description4 }}</h6>
    }
    @if (value()?.language?.description5 | isDefinedNotEmpty) {
      <h6 class="text-sm text-light">{{ value()?.language?.description5 }}</h6>
    }
  </div>
</div>
