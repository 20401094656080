export interface KeyNameObject {
  key: string;
  name: string;
}

export interface CodeNameObject {
  code: string;
  name: string;
}

export interface ValueDescriptionObject {
  value: string;
  description: string;
}

export interface Product {
  key: string;
  language: {
    name: string;
    description1: string;
    description2: string;
    description3: string;
    description4: string;
    description5: string;
  };
  mainGroup: KeyNameObject;
  subGroup: KeyNameObject;
  subSubGroup: KeyNameObject | null;
  productGroup: KeyNameObject;
  tax: CodeNameObject;
  accountingGroup: KeyNameObject;
  margin?: number |null;
  status: ValueDescriptionObject;
  type: ValueDescriptionObject;
  stockStructure: ValueDescriptionObject;
  stockManaged: ValueDescriptionObject;
  listedOn: string;
  plantRegistrationNumber?: number | string | null;
  discontinuedOn?: string | null;
  deletedOn?: string | null;
  productChange: {
    user: {
      username: string;
      fullName: string;
    };
    updatedAt: string;
  } | null;
  units: {
    consumerUnit: KeyNameObject;
    dispatchUnit: KeyNameObject;
    priceUnit: KeyNameObject;
    salesUnit: KeyNameObject;
    storageUnit: KeyNameObject;
    statisticUnit: KeyNameObject;
  }
}
