import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit,
  output, Type,
  untracked
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  TabComponent,
  TabGroupComponent,
  UiCoreDirectivesModule,
  UiCoreModule,
  WithBreakpoints
} from '@softline/ui-core';
import {BottomTabConfig, ScanButtonComponent, WithActivatedRoute, WithBottomTabGroup} from '@softline/application';
import {SearchStrategy} from './search.strategy';
import {BehaviorSubject, debounceTime, distinctUntilChanged} from 'rxjs';
import {toSignal} from '@angular/core/rxjs-interop';
import {SearchResultComponent, SOFTLINE_CONFIG_SEARCH_PAGE_RESULT_VIEW} from './abstraction/search-result.component';
import {Router} from '@angular/router';

const TAB_MAP = {
  0: 'search',
  1: 'scan',
  2: 'favorite'
}

@Component({
  selector: 'soft-search-page',
  standalone: true,
  imports: [CommonModule, UiCoreDirectivesModule, ScanButtonComponent, TabComponent, TabGroupComponent, UiCoreModule],
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPageComponent<T> extends WithBottomTabGroup(
  WithBreakpoints(
    WithActivatedRoute(
    )
  )
) implements OnInit {

  private readonly router = inject(Router);

  override bottomTabs = computed(() => {
    const config: BottomTabConfig[] = [];

    const breakpoint = this.breakpoint();

    if (breakpoint === null) {
      config.push(...[
        {
          name: TAB_MAP['0'],
          icon: 'fa-light fa-magnifying-glass',
          title: 'Suche'
        },
        {
          name: TAB_MAP['1'],
          icon: 'fa-light fa-barcode',
          title: 'Scannen',
        },
        {
          name: TAB_MAP['2'],
          icon: 'fa-light fa-heart',
          title: 'Favoriten',
        },
      ])
    }

    return config;
  });

  readonly searchInput$ = new BehaviorSubject<string | null>(null);

  readonly debouncedSearchInput = toSignal(
    this.searchInput$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ), { initialValue: null }
  );

  canScan = input(true);

  readonly select = output<T>();
  readonly favoriteChange = output<T>();

  readonly strategy = inject(SearchStrategy);
  readonly overrideResultComponent = inject(SOFTLINE_CONFIG_SEARCH_PAGE_RESULT_VIEW, { optional: true });

  get resultComponent(): Type<SearchResultComponent<T>> {
    return this.overrideResultComponent ?? this.strategy.resultViewType;
  }

  readonly tabIndex = computed(() => {
    return Object.values(TAB_MAP).indexOf(this.strategy.tab()) ?? 0;
  });

  private readonly _onSearchInputChangeEffect = effect(async () => {
    const searchInput = this.debouncedSearchInput();
    this.strategy.searchInputChange(searchInput ?? null);
    await this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParams: !searchInput ? null : {
        searchTerm: searchInput
      }
    });
  }, { allowSignalWrites: true });

  private readonly _onStrategyTabIndexChange = effect(() => {
    this.bottomTabGroupService.setActiveTab(this.BOTTOM_TAB_GROUP_NAME, this.strategy.tab());
  }, { allowSignalWrites: true });

  private readonly _onBottomTabGroupIndexChange = effect(() => {
    const activeTab = this.activeTab();
    const strategyTab = untracked(() => this.strategy.tab());

    if (strategyTab !== activeTab) {
      this.strategy.tab.set((Object.values(TAB_MAP).find(o => o === activeTab) as any) ?? 'search');
    }
  }, { allowSignalWrites: true });

  constructor() {
    super();
  }

  ngOnInit(): void {
    const queryParamsSearchTerm = this.routeQueryParams()['searchTerm'];

    if (queryParamsSearchTerm) {
      this.searchInput$.next(queryParamsSearchTerm);
    }
  }

  tabIndexChange(index: number): void {
    this.strategy.tab.set(TAB_MAP[index] ?? 'search');
  }
}
