<!-- Sticky Header (Tabs) -->
<div class="flex flex-col flex-shrink-0 sticky -mt-2 top-[-0.5rem] bg-background full-width border-b">
  @if (breakpoint() !== null) {
    <soft-tab-group class="flex flex-col flex-grow z-10" [customClass]="'w-full'" [activatedIndex]="tabIndex()" (activatedIndexChange)="tabIndexChange($event)">
      <soft-tab title="Suche"></soft-tab>

      @if (canScan()) {
        <soft-tab [title]="'Scannen'"></soft-tab>
      }

      <soft-tab [title]="'Favoriten'"></soft-tab>
      <soft-tab [title]="'Zuletzt verwendet'"></soft-tab>
    </soft-tab-group>
  }

  @if (tabIndex() === 0) {
    <div class="flex flex-col px-4 mb-0.5">
      <soft-input placeholder="Suchbegriff eingeben..." [ngClass]="[breakpoint() !== null ? 'mt-4' : 'mt-3']" class="mb-3" [value]="searchInput$ | async" (valueChange)="searchInput$.next($event || null)"></soft-input>

      @if (strategy.loadingState() === 'loaded') {
        <div class="text-light text-sm">{{ strategy.results()?.length ?? 0 }} Ergebnisse</div>
      }
    </div>
  }
</div>

<!-- Tab Content -->
@switch (tabIndex()) {
  @case (1) {
    <ng-container *ngTemplateOutlet="scanTemplate"></ng-container>
  }
  @case (2) {
    <ng-container *ngTemplateOutlet="favoriteTemplate"></ng-container>
  }
  @case (3) {
    <ng-container *ngTemplateOutlet="lastUsedTemplate"></ng-container>
  }
  @default {
    <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
  }
}

<ng-template #searchTemplate>
  <ul class="full-width flex flex-col flex-grow">
    @if (strategy.loadingState() === 'loading') {
      <li class="flex flex-col px-4 py-2 gap-2 bg-surface border-b w-full" *softRepeat="9">
        <div class="skeleton w-1/3 h-4"></div>
        <div class="skeleton w-1/2 h-5"></div>
        <div class="skeleton w-1/2 h-3"></div>
      </li>
      <li class="flex px-4 py-2 items-center justify-between gap-2 bg-surface border-t w-full border-b"></li>
    } @else if (strategy.loadingState() === 'loaded') {
      @for (result of strategy.results(); track $index; let first = $first; let last = $last) {
        <li class="flex px-4 py-2 items-center hover:bg-hover justify-between gap-2 bg-surface w-full"
            [class.border-t]="!first"
            [class.border-b]="last">
          <a (click)="select.emit(result)" class="w-full cursor-pointer">
            <ng-container *ngComponentOutlet="resultComponent; inputs: { result: result }"></ng-container>
          </a>
          <div class="flex items-center justify-center w-8 h-8 p-1 cursor-pointer hover:bg-white hover:text-primary rounded" (click)="favoriteChange.emit(result)">
            <soft-icon name="fa-regular fa-heart hover:text-primary"></soft-icon>
          </div>
        </li>
      } @empty {
        <div class="flex flex-col flex-grow w-full items-center justify-center px-4 text-center">
          <soft-icon name="fa-regular fa-face-pensive text-4xl mb-2"></soft-icon>
          <h6 class="font-semibold text-lg text-center px-4">Keine Treffer!</h6>
          <span class="text-sm text-light text-center px-4">Wir konnten zum Suchbegriff "{{debouncedSearchInput()}}" keine Treffer finden.</span>
        </div>
      }
    } @else if (strategy.loadingState() === 'error') {
      <div class="flex flex-col flex-grow w-full items-center justify-center px-4 text-center">
        <soft-icon name="fa-regular fa-cloud-exclamation text-4xl mb-2"></soft-icon>
        <h6 class="font-semibold text-lg text-center px-4">Fehler beim Laden der Ergebnisse!</h6>
        <span class="text-sm text-light text-center px-4">Bitte versuchen Sie es später noch einmal.</span>
      </div>
    } @else {
      <!-- IDLE State -->
      <div class="flex flex-col flex-grow w-full items-center justify-center px-4 text-center">
        <soft-icon name="fa-regular fa-magnifying-glass text-4xl mb-2"></soft-icon>
        <h6 class="font-semibold text-lg text-center px-4">Bitte Suchbegriff eingeben</h6>
        <span class="text-sm text-light text-center px-4">Die Suche wird automatisch ausgeführt sobald mehr als zwei Buchstaben eingegeben werden</span>
      </div>
    }
  </ul>
</ng-template>

<ng-template #scanTemplate>
  <soft-scan-button
    class="mt-2"
    [labelType]="['ean13', 'ean128', 'ean8', 'qrcode', 'code39', 'code93', 'code128', 'upc_a', 'upc_e', 'itf']">
  </soft-scan-button>
</ng-template>

<ng-template #favoriteTemplate>
  Favoriten...
</ng-template>


<ng-template #lastUsedTemplate>
  Zuletzt verwendet...
</ng-template>
