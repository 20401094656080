import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import moment from 'moment';
import {
  BackNavigable,
  MenuItem,
  WithMenuItems,
  WithQueryCollectionRepository, WithRouter,
  WithStaticBackNavigation,
  WithStaticTitleAndSubtitle
} from '@softline/application';
import { TimeReport } from '../../models/time-report.model';
import { Router } from '@angular/router';
import { TimeReportStore2 } from '../../store/time-report.store';
import { DynamicModule } from '@softline/dynamic';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';
import { ListPageComponent, MetaQueryBarComponent, PageHeaderComponent, PageSummaryComponent } from '@softapps/core';
import { timeReportQueryMeta } from '../../models/meta/time-report-query.meta';
import { TimeReportItemComponent } from '../../components/time-report-item/time-report-item.component';
import { timeReportMeta } from '../../models/meta/time-report.meta';
import { SOFTLINE_COMPONENT_TIME_REPORT_ITEM } from '../../arbeitsbericht.shared';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report-query-result',
  standalone: true,
  templateUrl: './query-result.component.html',
  styleUrls: ['./query-result.component.scss'],
  imports: [CommonModule, UiCoreModule, DynamicModule, ListPageComponent, PageHeaderComponent, MetaQueryBarComponent, TimeReportItemComponent, PageSummaryComponent]
})
export class QueryResultComponent
  extends
    WithQueryCollectionRepository({
      store: TimeReportStore2,
      queryFeature: (o) => o.query,
      collectionFeature: (o) => o.collection,
      repositoryFeature: (o) => o.repository,
    },
    WithRouter(
    WithMenuItems(
    WithStaticBackNavigation('/arbeitsbericht/query',
    WithStaticTitleAndSubtitle('#TIME_REPORT.COMPONENTS.QUERY_RESULT.TITLE', '#TIME_REPORT.COMPONENTS.QUERY_RESULT.SUBTITLE')
    ))))
  implements OnInit, OnDestroy, BackNavigable {

  timeReportComponent = inject(SOFTLINE_COMPONENT_TIME_REPORT_ITEM);

  timeReportStore = inject(TimeReportStore2);


  override menuItems = signal<MenuItem[]>([
    {
      outlet: 'nav',
      type: 'route',
      icon: 'fa-regular fa-list',
      priority: 3,
      name: '#TIME_REPORT.MENU.QUERY_RESULT',
      title: '#TIME_REPORT.MENU.QUERY_RESULT',
      class: 'menu main-menu main-menu-top',
      routerLink: ['/arbeitsbericht', 'result'],
    },
  ]);

  queryMeta = timeReportQueryMeta;
  meta = timeReportMeta;

  constructor() {
    super();
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  sum(reports: TimeReport[]): string {
    return reports
      .reduce((p, c) => p.add(c.duration), moment.duration(0))
      .toISOString();
  }

  async onItemClick(item: TimeReport): Promise<void> {
    await this.router.navigate(['/arbeitsbericht', item.id, 'edit'])
  }
}
