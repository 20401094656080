import {Component, input, signal, Signal, Type} from '@angular/core';
import {SearchResultComponent} from './abstraction/search-result.component';

export abstract class SearchStrategy<T, TFavorite = T> {
  tab = signal<'search' | 'scan' | 'favorite'>('search');

  abstract get resultViewType(): Type<SearchResultComponent<T>>

  abstract results: Signal<T[]>;
  abstract favorites: Signal<T[]>;
  abstract loadingState: Signal<'loaded' | 'loading' | 'idle' | 'error'>;

  abstract favoriteChange(value: T): void;
  abstract loadFavorites(): Promise<TFavorite[]>;

  abstract search(value: string | null): Promise<T[]>;
  abstract searchInputChange(value: string | null): void;
}

@Component({
  selector: 'soft-search-page-default-result-view',
  template: `
    <ng-content></ng-content>
  `,
  standalone: true,
  styles: []
})
export class DefaultSearchResultViewComponent implements SearchResultComponent<any> {
  public result = input.required<any>();
}
