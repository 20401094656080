import {DefaultSearchResultViewComponent, SearchStrategy} from './../search.strategy';
import {FieldOkParameters, FieldOkService, FieldOkServiceOptions} from '@softline/dynamic';
import {inject, InjectionToken, signal, Type} from '@angular/core';
import {catchError, distinctUntilChanged, filter, firstValueFrom, Observable, of, Subject, switchMap, tap} from 'rxjs';
import {toSignal} from '@angular/core/rxjs-interop';
import {ConnectionHttpService} from '@softline/core';
import {SearchResultComponent} from '../abstraction/search-result.component';

export interface FieldOkSearchStrategyConfig<T extends object> {
  queryFormatter?: (query: string | null) => FieldOkParameters<T>
}

export interface FieldOkSearchConfig<T extends object> {
  name: string;
  view: Type<SearchResultComponent<any>>;
  options?: {
    autoLoad?: boolean;
    serviceOptions?: FieldOkServiceOptions<T>;
  }
}

export const SOFTLINE_CONFIG_FIELD_OK_SEARCH_PAGE = new InjectionToken<FieldOkSearchConfig<any>[]>('FIELD_OK_SEARCH_VIEW');

export class FieldOkSearchStrategy<T extends object, TFavorite extends object> extends SearchStrategy<T, TFavorite> {

  protected readonly searchStream$ = new Subject<string | null>();

  protected fokService: FieldOkService<T>;
  protected fokSearchConfig?: FieldOkSearchConfig<any>;

  override get resultViewType() {
    return this.fokSearchConfig?.view ?? DefaultSearchResultViewComponent;
  }

  override results= toSignal(
    this.searchStream$.pipe(
      filter(o =>
        this.fokSearchConfig?.options?.autoLoad === true
          ? true
          : (!!o && o?.length > 2)
      ),
      distinctUntilChanged(),
      switchMap(filter =>
        this.query(filter).pipe(
          tap({
            subscribe: () => this.loadingState.set('loading'),
            unsubscribe: () => this.loadingState.set('idle'),
            complete: () => this.loadingState.set('loaded'),
            error: () => this.loadingState.set('error'),
          }),
          catchError(() => of([]))
        )
      ),
    ), { initialValue: [] }
  );

  override favorites = signal<T[]>([]);
  override loadingState = signal<'loaded' | 'loading' | 'idle' | 'error'>('idle');

  constructor(protected fieldOkName: string, protected config?: FieldOkSearchStrategyConfig<T>) {
    const connectionHttpService = inject(ConnectionHttpService);
    const searchConfigs = inject(SOFTLINE_CONFIG_FIELD_OK_SEARCH_PAGE)

    super();

    this.fokSearchConfig = searchConfigs.find(o => o.name === this.fieldOkName);

    if (!this.fokSearchConfig) {
      console.warn(`[FieldOkSearchStrategy] - No configuration with name "${fieldOkName}" for token FIELD_OK_SEARCH_VIEW!`)
    }

    this.fokService = new FieldOkService<T>(connectionHttpService, fieldOkName, this.fokSearchConfig?.options?.serviceOptions);
  }

  searchInputChange(value: string | null) {
    if ((!value || value?.length <= 2) && this.loadingState() !== 'idle' && this.fokSearchConfig?.options?.autoLoad !== true) {
      this.loadingState.set('idle');
    }

    this.searchStream$.next(value);
  }

  loadFavorites(): Promise<TFavorite[]> {
    return Promise.resolve([]);
  }

  async search(value: string | null): Promise<T[]> {
    return await firstValueFrom(this.query(value));
  }

  favoriteChange(value: T) {}

  private query(filter: string | null): Observable<T[]> {
    return this.fokService.query(
      this.config?.queryFormatter
        ? this.config.queryFormatter(filter)
        : {
          filter: filter ?? '',
          multiValued: false,
          maxAbfrageResults: 1000,
          parameters: {}
        }
    ) as Observable<T[]>;
  }
}
