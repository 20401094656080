import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { NavigationExtras, Router, RouterModule } from "@angular/router";
import { isDefined, isDefinedNotEmpty, Store } from "@softline/core";
import * as ShellStore from "../../shell.store";
import { map, switchMap, tap } from "rxjs/operators";
import { BreakpointStore, SOFTLINE_FEATURE_BREAKPOINTS, UiCoreModule } from "@softline/ui-core";
import { asapScheduler, combineLatest, Observable, observeOn, of, zip } from "rxjs";
import { CommonModule } from "@angular/common";
import {
  BackNavigationService,
  Command,
  CommandStore,
  SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_SHELL,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';

@Component({
  standalone: true,
  selector: 'soft-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule, RouterModule],
})
export class TitleBarComponent implements OnInit {
  title$: Observable<string> = this.store.observe(
    SOFTLINE_FEATURE_TITLE,
    TitleStore.getters.title
  );
  subTitle$: Observable<string> = this.store.observe(
    SOFTLINE_FEATURE_TITLE,
    TitleStore.getters.subTitle
  );

  leftCommands$: Observable<readonly Command[]> = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'top-menu-left'
    )
    .pipe(observeOn(asapScheduler));

  mainMenuBadges$ = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'main-menu'
    )
    .pipe(
      observeOn(asapScheduler),
      map(o => o.map(p => p.badge ?? of(undefined))),
      switchMap( o => combineLatest(o)),
      map(o => o
        .filter(p => isDefinedNotEmpty(p))
        .map(p => p as unknown as string)
      ),
      map(o=> {
        const numberBadge = o.map(p => Number.parseInt(p))
        if(numberBadge.length > 0 && numberBadge.every(Number.isInteger))
          return numberBadge.reduce((p, c) => p + c, 0)
        else if (o.length > 0)
          return '!'
        else
          return undefined;
      })
    )
  actionMenuBadges$ = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'action-menu'
    )
    .pipe(
      observeOn(asapScheduler),
      map(o => o.map(p => p.badge ?? of(undefined))),
      switchMap( o => combineLatest(o)),
      map(o => o
        .filter(p => isDefinedNotEmpty(p))
        .map(p => p as unknown as string)
      ),
      map(o=> {
        const numberBadge = o.map(p => Number.parseInt(p))
        if(numberBadge.length > 0 && numberBadge.every(Number.isInteger))
          return numberBadge.reduce((p, c) => p + c, 0)
        else if (o.length > 0)
          return '!'
        else
          return undefined;
      })
    )


  rightCommands$: Observable<readonly Command[]> = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'top-menu-right'
    )
    .pipe(observeOn(asapScheduler));

  isMobileScreen$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_BREAKPOINTS,
    BreakpointStore.getters.match,
    null
  );
  hasSideActions$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_COMMANDS,
    CommandStore.getters.hasCommands,
    'action-menu'
  );

  isOpen = false;

  constructor(
    private store: Store,
    private router: Router,
    public backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {}

  onClick(
    canExecute: boolean,
    routerLink: any[] | string | undefined,
    execute: (() => void) | undefined,
    routerLinkParams: NavigationExtras | undefined
  ): void {
    if (!canExecute) return;
    if (execute) execute();
    else if (routerLink)
      this.router.navigate(
        typeof routerLink === 'string' ? [routerLink] : routerLink,
        routerLinkParams
      );
  }

  maximizeSideMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.maximizeSideMenu
    );
  }
  maximizeActionMenu(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SHELL,
      ShellStore.mutations.maximizeActionMenu
    );
  }
}
