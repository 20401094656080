import { CollectionStore2 } from '../collection.store2';
import { inject, Injectable } from '@angular/core';
import { SumStore2 } from '../sum.store';

export interface CollectionResponseCommit<T, TMany = T[]> {
  add(value: T): void;
  addMany(values: TMany): void;
  update(value: T): void;
  remove(value: T): void;
  clear(): void;
}

@Injectable()
export class DefaultCollectionResponseCommit<T extends object> implements CollectionResponseCommit<T> {
  collection = inject(CollectionStore2<T>);

  add(value: T): void {
    this.collection.addOrUpdate(value)
  }

  addMany(values: T[]): void {
    this.collection.addOrUpdateMany(values)
  }
  update(value: T): void {
    this.collection.update(value)
  }

  remove(value: T): void {
    this.collection.remove(value)
  }

  clear(): void {
    this.collection.clear()
  }
}


@Injectable()
export class ResultCollectionResponseCommit<T extends object> implements CollectionResponseCommit<{ result: T}, { result: T[]}> {
  collection = inject(CollectionStore2<T>);

  add(value: { result: T }): void {
    this.collection.addOrUpdate(value.result)
  }

  addMany(values: { result: T[] }): void {
    if (Array.isArray(values?.result) === false) {
      console.warn('RESPONSE is not an Array - Please check the HTTP Result! Response is: ', values);
      return;
    }

    this.collection.addOrUpdateMany(values.result)
  }

  update(value: {result: T}): void {
    this.collection.update(value.result)
  }

  remove(value: {result: T}): void {
    this.collection.remove(value.result)
  }

  clear(): void {
    this.collection.clear()
  }
}

@Injectable()
export class SumCollectionResponseCommit<T extends object> implements CollectionResponseCommit<{ }, { sum: Partial<T[]> }> {
  sum = inject(SumStore2<T>);

  add(result: { }): void { }

  addMany(values: { sum: T[] }): void {
    this.sum.clear();
    console.log('-------', values);
    this.sum.addMany(values?.sum ?? [])
  }

  clear(): void {
    this.sum.clear()
  }

  update(result: {}): void { }
  remove(result: {}): void { }
}

export class MultiCollectionResponseCommit<T extends object, TMany = T[]> implements CollectionResponseCommit<T, TMany> {

  private strategies: CollectionResponseCommit<T, TMany>[];

  constructor(...strategies: CollectionResponseCommit<T, TMany>[]) {
    this.strategies = strategies;
  }

  add(value: T): void {
    for(const strategy of this.strategies)
      strategy.add(value);
  }

  addMany(values: TMany): void {
    for(const strategy of this.strategies)
      strategy.addMany(values);
  }
  update(value: T): void {
    for(const strategy of this.strategies)
      strategy.update(value);
  }

  remove(value: T): void {
    for(const strategy of this.strategies)
      strategy.remove(value);
  }

  clear(): void {
    for(const strategy of this.strategies)
      strategy.clear();
  }
}
