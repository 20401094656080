import {DateService, Dictionary, InjectableStore2Feature} from '@softline/core';
import {computed, inject, Injectable} from '@angular/core';
import { Favorite } from './favorite';

@Injectable({
  providedIn: 'root'
})
export class FavoriteStore2<T> extends InjectableStore2Feature<Readonly<Dictionary<T[]>>> {

  dateService = inject(DateService);

  favorites = (key: string) => computed(() => {
    const value = this.state()[key] as Favorite<T>[];
    return value ?? [];
  })

  constructor() {
    super();
  }

  add(key: string, id: string | number, object: T): void {
    const timestamp = this.dateService.now();
    let favorites = this.state()[key] as Favorite<T>[] ?? [];
    favorites = [{ id, object, timestamp }, ...favorites];
    this.commitPatch({ [key]: favorites } as any);
  }

  remove(key: string, id: string | number): void {
    let favorites = this.state()[key] as Favorite<T>[] ?? [];
    favorites = favorites.filter(favorite => favorite.id !== id);
    this.commitPatch({ [key]: favorites } as any);
  }

  clear(): void {
    this.commit({ });
  }

  override getDefaultState(): Dictionary<T[]> {
    return {} as Dictionary<T[]>;
  }
}
