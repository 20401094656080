import { MetaObject } from '@softline/dynamic';
import { TimeReport } from '../time-report.model';

export const timeReportMeta: MetaObject<TimeReport> = {
  id: {
    type: 'string',
    visible: false
  },
  date: {
    type: 'date',
    format: 'DATE.DEFAULT',
    visible: false
  },
  description: {
    type: 'string',
    visible: false
  },
  from: {
    type: 'time',
    format: 'DATE.DEFAULT',
    visible: false
  },
  to: {
    type: 'time',
    format: 'DATE.DEFAULT',
    visible: false
  },
  kst: {
    type: 'fieldOk',
    visible: true,
    format: '{{kostenstelle}} - {{kontotext1}}',
    of: {
      id: { type: 'number' },
      kostenstelle: { type: 'number' },
      kontotext1: { type: 'string' }
    }
  },
  object: {
    type: 'object',
    visible: true,
    format: '{{number}} - {{name}}',
    object: {
      id: { type: 'number' },
      number: { type: 'number' },
      name: { type: 'string' }
    }
  },
  zeit: {
    type: 'duration',
    visible: true,
    format: 'DURATION.DEFAULT',
  },
  kzverrzeit: {
    type: 'select',
    visible: true,
    options: [
      { value: 'A', label: 'Auftrag', icon: 'check' },
      { value: 'L', label: 'Lieferschein', icon: 'close' },
      { value: 'R', label: 'Regie', icon: 'close' },
      { value: 'K', label: 'Keine', icon: 'close' }
    ],
  },
  zbewe: {
    type: 'object',
    format: '',
    visible: false,
    object: {}
  },
  zbewegungsarten: {
    type: 'array',
    visible: true,
    of: {
    },
  }
}
